<template>
  <div class="loading">
    <!-- <img src="@/assets/loadinglogo.svg" class="logo" width="100" />
          <div class="bounce">
            <span class="letter">L</span>
            <span class="letter">O</span>
            <span class="letter">A</span>
            <span class="letter">D</span>
            <span class="letter">I</span>
            <span class="letter">N</span>
            <span class="letter">G</span>
          </div> -->
    <div class="loadingDiv">
      <img src="@/assets/logo.svg" class="logo mb-3" width="100"/>
      <v-progress-linear
        color="primary accent-4"
       
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  min-height: 20vh;
}


// CONFIGURABLE
$bounce-time: 1.5s;
$bounce-letter-delay-factor: 1/3;
$bounce-timing-function: cubic-bezier(0.05, 0, 0.2, 1);
$bounce-height: 1em;
$shadow-opacity: 0.4;
$shadow-scattering-initial: 0.01em;
$shadow-scattering-peak: 0.55em;
$font-family: "Product Sans"; // preferably sans-serif
$font-size: 1.3rem;
$font-weight: bold;
$font-style: normal;
$letter-colors: #409fd5, #42a1d5, #2a86c7, #2583c5, #2a87c8, #3797d1, #2a86c7,
  #2784c6; // each letter must be assigned a color individually

.bounce {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font: $font-style $font-weight $font-size $font-family, sans-serif;
  white-space: nowrap;
}

.letter {
  animation: bounce $bounce-time / 2 $bounce-timing-function infinite alternate;
  display: inline-block;
  transform: translate3d(0, 0, 0);
  margin-top: 0.5em;
  text-shadow: rgba(#000, $shadow-opacity) 0 0 $shadow-scattering-initial;
  font: $font-style $font-weight $font-size $font-family, sans-serif;
  @for $i from 1 through length($letter-colors) {
    &:nth-child(#{$i}) {
      animation-delay: ($i - 1) *
        ($bounce-time / length($letter-colors)) *
        $bounce-letter-delay-factor;
      color: nth($letter-colors, $i);
    }
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
    text-shadow: rgba(#000, $shadow-opacity) 0 0 $shadow-scattering-initial;
  }
  100% {
    transform: translate3d(0, -$bounce-height, 0);
    text-shadow: rgba(#000, $shadow-opacity) 0 $bounce-height
      $shadow-scattering-peak;
  }
}
</style>